import { ProgressContainer } from 'containers';
import { useNavigate, useParams } from 'react-router-dom';
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import NoMatches from 'assets/no_relationships_avatar.svg';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  ICustomField,
  IMembership,
  IProfile,
  ProfileSkill,
  Program,
  IGroup,
  IMatchV2,
} from '@guider-global/shared-types';
import { GroupProfileView } from '@guider-global/ui';
import {
  Button,
  CircularAvatarPair,
  CustomModal,
  Loading,
  TextStack,
} from '@guider-global/ui/lib/components';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { MDIcons, SanityIcon } from 'components';
import { useLocalization, useMatchesV2 } from 'hooks';
import { useState } from 'react';
import { NoMatchesView } from 'views';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import ConfettiExplosion from 'react-confetti-explosion';
import { interpolate } from 'functions';
import {
  useMemberships,
  useRelationships,
  useUsers,
} from '@guider-global/front-end-hooks';

export function GroupMatchesPage() {
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  // State
  const [matchingLoading, setMatchingLoading] = useState<boolean>(false);
  const [selectedProfileId, setSelectedProfileId] = useState<
    string | undefined
  >(undefined);
  const [showMatchedModal, setShowMatchedModal] = useState<boolean>(false);
  const [selectedMatch, setSelectedMatch] = useState<IMatchV2 | undefined>();

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { getProfileImage } = useProfileImage();

  // Organization

  const { organization } = useOrganization({
    organizationSlug,
  });

  // User
  const { users } = useUsers({});
  const user = users?.at(0);

  // Program
  const { getProgram } = useOrganizationPrograms({ organizationSlug });
  const program = getProgram(programSlug);

  const groupRegistrationText =
    program?.program_details?.program_type?.program_type_text?.variations?.group
      ?.registration?.registration_trainee;
  const groupProfileCardText =
    program?.program_details?.program_type?.program_type_text?.variations?.group
      ?.registration?.profile_card;

  // Base Language

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({
    localeCode,
  });

  // Memberships
  const { memberships, membershipsRevalidate, isLoadingMemberships } =
    useMemberships({});

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === 'trainee',
  );

  // Strings and assets

  const matchesToDisplay = program?.registration?.matches_to_display;

  // Matching

  const {
    matchesV2: matches,
    isLoadingMatchesV2,
    isErrorMatchesV2,
  } = useMatchesV2({
    options: { keepPreviousData: false },
    query: `?resultType=guideGroup&organizationSlug=${organizationSlug}&programSlug=${programSlug}${
      matchesToDisplay ? `&pageLimit=${matchesToDisplay}` : ''
    }`,
  });

  // Relationships

  const { reqRelationships, isMutatingRelationships, relationshipsRevalidate } =
    useRelationships({});

  // Handle match

  const handleMatching = async (id: string | undefined, match: IMatchV2) => {
    if (!membership || !id) return;

    setMatchingLoading(true);
    setSelectedProfileId(id);

    const membershipProfileId = (membership?.profile as Partial<IProfile>).id;

    if (!membershipProfileId) return;

    const relationship = match?.relationship;

    const relationshipId = relationship?.id;

    const result = await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationshipId}`,
      data: {
        traineeProfiles: [membershipProfileId],
      },
    });

    relationshipsRevalidate();
    membershipsRevalidate();

    if (result.status === 'success' && result.data) {
      setSelectedMatch(match);
      setShowMatchedModal(true);
      setMatchingLoading(false);
    } else {
      setMatchingLoading(false);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: result?.message,
          timeout: 5000,
        }),
      );
    }
  };

  if (!matches && !isErrorMatchesV2)
    return (
      <ProgressContainer
        percentage={100}
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button
            variant="text"
            onClick={() => navigate('/dashboard')}
            label={baseLanguage?.globals?.common?.skip_button_label}
          />
        }
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  if (isLoadingMatchesV2 || isLoadingMemberships)
    return (
      <ProgressContainer
        percentage={100}
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button
            variant="text"
            onClick={() => navigate('/dashboard')}
            label={baseLanguage?.globals?.common?.skip_button_label}
          />
        }
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  if (matches?.length === 0 || isErrorMatchesV2) {
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button
            variant="text"
            onClick={() => navigate('/dashboard')}
            label={baseLanguage?.globals?.common?.skip_button_label}
          />
        }
        percentage={100}
      >
        <NoMatchesView
          imageSrc={NoMatches}
          title={
            groupRegistrationText?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_title
          }
          description={
            groupRegistrationText?.registration_trainee_no_matches_found
              ?.registration_trainee_no_matches_found_description
          }
          buttons={[
            {
              key: 'matches-btn-1',
              variant: 'outlined',
              label: baseLanguage?.globals?.common?.go_back_button_label,
              color: 'info',
              onClick: () => navigate(-1),
            },
            {
              key: 'matches-btn-2',
              variant: 'contained',
              label: baseLanguage?.globals?.common?.continue_button_label,
              color: 'info',
              onClick: () => navigate('/dashboard'),
            },
          ]}
        />
      </ProgressContainer>
    );
  }

  // Match modal

  const matchImage =
    getProfileImage({
      profilePicture: selectedMatch?.profile?.picture,
      userPicture: selectedMatch?.user?.picture,
    }) ?? '';

  const userImage =
    getProfileImage({
      profilePicture: (membership?.profile as IProfile)?.picture,
      userPicture: user?.picture,
    }) ?? '';

  return (
    <ProgressContainer
      percentage={100}
      onBackButtonClick={() => navigate(-1)}
      endAdornment={
        <Button
          variant="text"
          onClick={() => navigate('/dashboard')}
          label={baseLanguage?.globals?.common?.skip_button_label}
        />
      }
    >
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '832px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <TextStack
          spacing={1.5}
          size="medium"
          heading={{
            text: groupRegistrationText?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: groupRegistrationText?.registration_trainee_choose_a_guide
                ?.registration_trainee_choose_a_guide_description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
        />

        {matches?.map((match, index) => {
          const profile = match?.profile as Partial<IProfile>;
          const group = match?.relationship?.group as Partial<IGroup>;

          const spotsLeftLabel = program?.relationships?.group_settings
            ?.max_trainees
            ? interpolate(groupProfileCardText?.spots_left_group_label, {
                spotsLeft: match?.relationshipSpotsLeft?.toString(),
              })
            : undefined;

          const profileFieldsData =
            getVisibleProfileFields(
              organization?.profile_fields,
              profile?.organizationFields as ICustomField[],
            ) ?? [];

          const profileFields = profileFieldsData?.map((field) => {
            return {
              icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
              label: field?.label?.toString() ?? '',
            };
          });

          const image = getProfileImage({
            profilePicture: profile?.picture,
            userPicture: match?.user?.picture,
          });

          const buttonLabel = groupProfileCardText?.join_group_button_label;

          const skills = match?.skills.map((skill, index) => {
            const allSkills = [
              ...(program?.registration?.skills?.soft_skills ?? []),
              ...(program?.registration?.skills?.hard_skills ?? []),
            ] as ProfileSkill[];

            let label =
              allSkills?.find((s) => s?.slug === skill?.fieldSlug)?.label ??
              undefined;

            if (label === '' || !label) {
              label =
                program?.registration?.skills?.options?.find(
                  (s) => s?.id?.current === skill?.fieldSlug,
                )?.label ?? undefined;
            }

            return {
              key: `${'label'}-${index}`,
              value: label ?? '',
              isMatched: skill?.matched ?? false,
            };
          });

          const matchSkills =
            match?.skills?.length !== 0
              ? {
                  title:
                    program?.registration?.skills?.trainee?.question_heading ??
                    program?.registration?.skills?.text?.input_label,
                  skills: skills ?? [],
                }
              : undefined;

          const customFields = getFormattedProgramFields(
            match?.membership as IMembership,
            program as Program,
          );

          const matchText =
            match?.percentage &&
            interpolate(baseLanguage?.profile_card?.match_percentage, {
              matchPercentage: match?.percentage.toFixed(0),
            });

          return (
            <GroupProfileView
              title={group?.title ?? ''}
              description={group?.description}
              introText={groupProfileCardText?.meet_your_guide_label}
              score={parseFloat(match?.percentage?.toFixed(2))}
              matchText={matchText}
              key={match?.user?.id}
              customFields={customFields}
              profileFields={profileFields}
              guideReviews={{
                reviewsPlural: baseLanguage?.profile_card?.reviews_plural,
                reviewsSingular: baseLanguage?.profile_card?.reviews_singular,
                buttonLabel: baseLanguage?.profile_card?.reviews_button_label,
                noReviewsText: baseLanguage?.profile_card?.no_reviews_yet_label,
                reviews: match?.reviews,
              }}
              displayName={profile?.displayName ?? ''}
              isBestMatch={index === 0}
              bestMatchLabel={
                baseLanguage?.programs?.program_registration?.best_match_label
              }
              subtitles={[
                profile?.jobTitle && {
                  text: profile?.jobTitle,
                  variant: 'subtitle1',
                },
                profile?.townOrCity && {
                  text: `${profile?.townOrCity}`,
                  variant: 'caption',
                  color: 'text.secondary',
                },
              ]}
              avatarSrc={image}
              skills={matchSkills}
              linkedInUrl={profile?.linkedInUrl}
              viewLinkedInLabel={
                baseLanguage?.programs?.program_registration
                  ?.linkedin_profile_button_label
              }
              bottomText={spotsLeftLabel}
              buttons={[
                {
                  label: buttonLabel,
                  key: '1',
                  variant: 'contained',
                  color: 'info',
                  sx: { px: 3 },
                  onClick: () => handleMatching(profile?.id, match),
                  loading:
                    matchingLoading &&
                    selectedProfileId === profile?.id &&
                    isMutatingRelationships,
                  disabled:
                    matchingLoading && selectedProfileId !== profile?.id,
                },
              ]}
            />
          );
        })}
      </Stack>
      <CustomModal isOpen={showMatchedModal}>
        <Stack direction={'row'} gap={0} spacing={0}>
          <ConfettiExplosion force={0.8} duration={5000} />
          <Stack
            direction={'column'}
            gap={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={5}
            py={4}
            width={'380px'}
          >
            <TextStack
              align="center"
              size="large"
              heading={interpolate(
                groupRegistrationText
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_title,
                {
                  groupName: (
                    selectedMatch?.relationship?.group as Partial<IGroup>
                  )?.title,
                },
              )}
              subtitles={[
                {
                  text: interpolate(
                    groupRegistrationText
                      ?.registration_trainee_relationship_created_successfully
                      ?.registration_trainee_relationship_created_successfully_description,
                    {
                      firstName:
                        (membership?.profile as IProfile)?.firstName ?? '',
                    },
                  ),
                  variant: 'subtitle1',
                  color: 'text.secondary',
                  align: 'center',
                },
              ]}
            />
            <CircularAvatarPair
              mainAvatar={matchImage}
              secondaryAvatar={userImage}
            />
            <Button
              fullWidth
              label={
                groupRegistrationText
                  ?.registration_trainee_relationship_created_successfully
                  ?.registration_trainee_relationship_created_successfully_view_relationship_button_label
              }
              variant={'contained'}
              color="info"
              onClick={() =>
                navigate(`/relationships/${selectedMatch?.relationship?.id}`)
              }
            />
          </Stack>
          <ConfettiExplosion force={0.8} duration={5000} />
        </Stack>
      </CustomModal>
    </ProgressContainer>
  );
}
