import { ProgressContainer } from 'containers';
import { useNavigate, useParams } from 'react-router-dom';
import { useProfileImage } from '@guider-global/azure-storage-hooks';
import guideIcon from 'assets/users.svg';
import {
  getFormattedProgramFields,
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  ICustomField,
  IMembership,
  ProfileSkill,
  Program,
} from '@guider-global/shared-types';
import { GroupProfileView } from '@guider-global/ui';
import { AvatarBox, Loading } from '@guider-global/ui/lib/components';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { MDIcons, SanityIcon } from 'components';
import { useLocalization } from 'hooks';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';
import { GroupsContext } from 'containers/Groups';
import {
  useMemberships,
  useProfiles,
  useRelationships,
  useUsers,
} from '@guider-global/front-end-hooks';

export function GroupPreviewPage() {
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const { groupValues } = useContext(GroupsContext);
  console.log(groupValues);

  // Hooks
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { getProfileImage } = useProfileImage();
  const dispatch = useDispatch();

  // Organization
  const { organization, isLoadingOrganization } = useOrganization({
    organizationSlug,
  });

  // Relationships
  const { reqRelationships, isMutatingRelationships } = useRelationships({});

  // Profile
  const { profiles, isLoadingProfiles } = useProfiles({});
  const profile = profiles?.at(0);

  // User
  const { users, isLoadingUsers } = useUsers({});
  const user = users?.at(0);

  // Program
  const { getProgram, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
  });
  const program = getProgram(programSlug);

  // Base Language

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage, isLoadingBaseLanguage } = useBaseLanguage({
    localeCode,
  });

  const groupProfileCardText =
    program?.program_details?.program_type?.program_type_text?.variations?.group
      ?.registration?.profile_card;

  // Memberships
  const { memberships, isLoadingMemberships } = useMemberships({});

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && membership?.role === 'guide',
  );

  // Groups
  const group = groupValues;

  async function handlePublish() {
    if (!membership || !program || !programSlug || !group || !profile) return;

    setLoading(true);

    const { data, status, message } = await reqRelationships({
      method: 'POST',
      url: '/relationships',
      data: {
        guideProfiles: [profile?.id],
        isConcluded: false,
        programSlug,
        organizationSlug,
        programTypeSlug:
          program?.program_details?.program_type?.metadata?.id?.current,
        programVariationTypeSlug: EProgramVariation.Group,
        profileId: profile.id,
        skills: group?.skillSlugs as string[],
        group: {
          title: group?.title as string,
          description: group?.description as string,
        },
      },
    });

    if (data && status === 'success') {
      const [relationship] = data;
      const relationshipId = relationship.id;
      navigate(`/relationships/${relationshipId}`);
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message,
          timeout: 5000,
        }),
      );
    }
    setLoading(false);
  }

  const customFields = getFormattedProgramFields(
    membership as IMembership,
    program as Program,
  );

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: user?.picture,
    }) ?? '';

  const profileFieldsData =
    getVisibleProfileFields(
      organization?.profile_fields,
      profile?.organizationFields as ICustomField[],
    ) ?? [];

  const profileFields = profileFieldsData?.map((field) => {
    return {
      icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
      label: field?.label?.toString() ?? '',
    };
  });

  const skills =
    group?.skillSlugs?.map((skill, index) => {
      const allSkills = [
        ...(program?.registration?.skills?.soft_skills ?? []),
        ...(program?.registration?.skills?.hard_skills ?? []),
      ] as ProfileSkill[];

      let label = allSkills?.find((s) => s?.slug === skill)?.label ?? undefined;

      if (label === '' || !label) {
        label =
          program?.registration?.skills?.options?.find(
            (s) => s?.id?.current === skill,
          )?.label ?? undefined;
      }

      return {
        key: `${label}-${index}`,
        isMatched: false,
        value: label ?? '',
      };
    }) ?? [];

  const isLoading =
    isLoadingMemberships ||
    isLoadingProfiles ||
    isLoadingUsers ||
    isLoadingBaseLanguage ||
    isLoadingPrograms ||
    isLoadingOrganization;

  if (isLoading && !loading)
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        percentage={100}
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={100}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '832px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <AvatarBox
          orientation="vertical"
          textSpacing={1.5}
          size="medium"
          heading={{
            text: program?.program_details?.program_type?.program_type_text
              ?.variations?.group?.registration?.registration_guide
              ?.registration_guide_review_profile
              ?.registration_guide_review_profile_title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: program?.program_details?.program_type?.program_type_text
                ?.variations?.group?.registration?.registration_guide
                ?.registration_guide_review_profile
                ?.registration_guide_review_profile_description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
          avatarSrc={guideIcon}
          avatarSx={{
            p: 1.25,
            border: 'none',
            backgroundColor: '#F5F5F5',
          }}
        />

        <GroupProfileView
          customFields={customFields}
          profileFields={profileFields}
          title={group?.title ?? ''}
          description={group?.description}
          introText={groupProfileCardText?.meet_your_guide_label}
          displayName={profile?.displayName ?? ''}
          subtitles={[
            {
              text: profile?.jobTitle ?? '',
              variant: 'subtitle1',
            },
            {
              text: profile?.townOrCity ?? '',
              variant: 'caption',
              color: 'text.secondary',
            },
          ]}
          avatarSrc={userImage}
          skills={{
            title:
              program?.registration?.skills?.guide?.question_heading ??
              program?.registration?.skills?.text?.input_label ??
              'Skills',
            skills,
          }}
          linkedInUrl={profile?.linkedInUrl}
          viewLinkedInLabel={
            baseLanguage?.programs?.program_registration
              ?.linkedin_profile_button_label
          }
          buttons={[
            {
              label:
                !group?.welcomeMessage || group?.welcomeMessage === ''
                  ? program?.program_details?.program_type?.program_type_text
                      ?.variations?.group?.registration?.registration_guide
                      ?.registration_guide_review_profile
                      ?.registration_guide_review_profile_publish_profile_button_label
                  : baseLanguage?.globals?.common?.save_changes_button_label,
              key: '1',
              variant: 'contained',
              color: 'info',
              sx: { px: 3 },
              onClick: handlePublish,
              loading: isMutatingRelationships || loading,
            },
          ]}
        />
      </Stack>
    </ProgressContainer>
  );
}
