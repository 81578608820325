import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { AvatarBox, Button, Checkbox, TextStack } from '@guider-global/ui';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { interpolate } from 'functions';

import guideIcon from 'assets/users.svg';
import traineeIcon from 'assets/learn.svg';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useEffect, useState } from 'react';
import { ISkill, ProfileSkill } from '@guider-global/shared-types';
import { useLocalization } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressContainer } from 'containers';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useProfiles } from '@guider-global/front-end-hooks';

export function SkillsPage() {
  const navigate = useNavigate();
  const { role } = useParams<{ role: 'trainee' | 'guide' }>();

  const [showMoreSoftSkills, setShowMoreSoftSkills] = useState<boolean>(false);
  const [showMoreHardSkills, setShowMoreHardSkills] = useState<boolean>(false);

  const [selectedHardSkills, setSelectedHardSkills] = useState<ProfileSkill[]>(
    [],
  );
  const [selectedSoftSkills, setSelectedSoftSkills] = useState<ProfileSkill[]>(
    [],
  );

  const organizationSlug = getSubDomain();

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const hardSkills = organization?.open_matching?.skills?.hard_skills ?? [];
  const softSkills = organization?.open_matching?.skills?.soft_skills ?? [];

  const { profile, reqProfiles, isLoadingProfiles } = useProfiles({
    params: { integrationsEnabled: true },
  });

  useEffect(() => {
    if (profile) {
      const existingSkillSlugs =
        (profile.skills as ISkill[])
          ?.filter((skill) => (role === 'guide' ? skill.offered : skill.sought))
          .map((skill) => skill.fieldSlug) ?? [];

      const existingHardSkills =
        hardSkillOptions?.filter(
          (skill) => skill.slug && existingSkillSlugs.includes(skill.slug),
        ) ?? [];

      const existingSoftSkills =
        softSkillOptions?.filter(
          (skill) => skill.slug && existingSkillSlugs.includes(skill.slug),
        ) ?? [];

      setSelectedHardSkills(existingHardSkills);
      setSelectedSoftSkills(existingSoftSkills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, role]);

  const hardSkillOptions = organization?.open_matching?.skills?.hard_skills;
  const softSkillOptions = organization?.open_matching?.skills?.soft_skills;

  async function handleSkillsSubmit(role: 'guide' | 'trainee') {
    if (!profile) return;

    const skillsData: Partial<ISkill> & {
      softSkillSlugs?: string[];
      hardSkillSlugs?: string[];
      role: 'trainee' | 'guide';
    } = {
      softSkillSlugs: selectedSoftSkills.map((skill) => skill.slug) as string[],
      hardSkillSlugs: selectedHardSkills.map((skill) => skill.slug) as string[],
      role,
    };

    const result = await reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile.id}`,
      data: skillsData,
    });

    if (result.status === 'success' && result.data) {
      setSelectedHardSkills([]);
      setSelectedSoftSkills([]);
      if (profile.roles?.includes('trainee')) {
        navigate('../../matches/guide');
      } else {
        await reqProfiles({
          method: 'PATCH',
          url: `/profiles/${profile.id}`,
          data: {
            isOnboarded: true,
          },
        });
        navigate('/dashboard');
      }
    }
  }

  function isSkillSelected(skill: ProfileSkill) {
    return [...selectedSoftSkills, ...selectedHardSkills].find(
      (s) => s.slug === skill.slug,
    )
      ? true
      : false;
  }

  const toggleSoftSkillSelect = (skill: ProfileSkill) => {
    isSkillSelected(skill)
      ? setSelectedSoftSkills([
          ...selectedSoftSkills.filter(
            (selected) => selected.slug !== skill.slug,
          ),
        ])
      : setSelectedSoftSkills([...selectedSoftSkills, skill]);
  };
  const toggleHardSkillSelect = (skill: ProfileSkill) => {
    isSkillSelected(skill)
      ? setSelectedHardSkills([
          ...selectedHardSkills.filter(
            (selected) => selected.slug !== skill.slug,
          ),
        ])
      : setSelectedHardSkills([...selectedHardSkills, skill]);
  };

  if (!baseLanguage || !role)
    return (
      <ProgressContainer>
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
      >
        <AvatarBox
          orientation="vertical"
          textSpacing={1.5}
          size="medium"
          heading={{
            text: interpolate(
              baseLanguage?.registration?.open_matching?.common?.preheader,
              {
                roleSingular:
                  organization?.open_matching?.program_type?.program_type_text
                    ?.common?.[role]?.singular ??
                  (role === 'guide' ? 'Mentor' : 'Mentee'),
              },
              '',
            ),
            variant: 'h6',
          }}
          subtitles={[
            {
              text:
                role === 'guide'
                  ? interpolate(
                      baseLanguage?.registration?.open_matching?.skills
                        ?.title_guide,
                      {
                        traineeSingular:
                          organization?.open_matching?.program_type
                            ?.program_type_text.common?.trainee?.singular ??
                          'Mentee',
                      },
                      'Which skills can you  Mentor someone on.',
                    )
                  : interpolate(
                      baseLanguage?.registration?.open_matching?.skills
                        ?.title_trainee,
                      {
                        guideSingular:
                          organization?.open_matching?.program_type
                            ?.program_type_text.common?.guide?.singular ??
                          'Mentor',
                      },
                      'Which skills would you like a Mentor to help you improve?',
                    ),
              variant: 'h2',
              lineHeight: '115%',
            },
            {
              text:
                organization?.open_matching?.skills
                  ?.select_skills_custom_description ??
                baseLanguage?.registration?.open_matching?.skills
                  ?.select_number_of_skills ??
                'You can select as many as you’d like.',
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
          avatarSrc={role === 'guide' ? guideIcon : traineeIcon}
          avatarSx={{
            p: 1.25,
            border: 'none',
            backgroundColor: '#F5F5F5',
          }}
        />

        <Stack direction={'column'} pt={2}>
          <TextStack
            size="xs"
            heading={
              baseLanguage?.registration?.open_matching?.skills
                ?.soft_skill_label
            }
            tooltipText={
              baseLanguage?.registration?.open_matching?.skills
                ?.soft_skill_tooltip
            }
          />
          {softSkills.length > 0 &&
            softSkills
              ?.slice(0, showMoreSoftSkills ? undefined : 5)
              ?.map((skill, index) => {
                return (
                  <Checkbox
                    key={`${skill.slug}-soft`}
                    variant="option"
                    heading={skill.label}
                    onChange={() => toggleSoftSkillSelect(skill)}
                    isChecked={isSkillSelected(skill) ?? false}
                    data-cy={`register-soft-skill-${index}`}
                  />
                );
              })}
          <Button
            sx={{
              display: softSkills?.length > 5 ? '' : 'none',
            }}
            variant="text"
            label={
              showMoreSoftSkills
                ? baseLanguage?.globals?.common?.show_less_button_label ??
                  'Less'
                : baseLanguage?.globals?.common?.show_more_button_label ??
                  'More'
            }
            endIcon={showMoreSoftSkills ? <ArrowDropUp /> : <ArrowDropDown />}
            onClick={() => setShowMoreSoftSkills(!showMoreSoftSkills)}
            data-cy="soft-skills-show-more-less-button"
          />
        </Stack>

        <Stack direction={'column'} pt={2}>
          <TextStack
            size="xs"
            heading={
              baseLanguage?.registration?.open_matching?.skills
                ?.hard_skill_label
            }
            tooltipText={
              baseLanguage?.registration?.open_matching?.skills
                ?.hard_skill_tooltip
            }
          />
          {hardSkills.length > 0 &&
            hardSkills
              ?.slice(0, showMoreHardSkills ? undefined : 5)
              ?.map((skill, index) => {
                return (
                  <Checkbox
                    key={`${skill.slug}-hard`}
                    variant="option"
                    heading={skill.label}
                    onChange={() => toggleHardSkillSelect(skill)}
                    isChecked={isSkillSelected(skill) ?? false}
                    data-cy={`register-hard-skill-${index}`}
                  />
                );
              })}
          <Button
            sx={{
              display: hardSkills?.length > 5 ? '' : 'none',
            }}
            variant="text"
            label={
              showMoreHardSkills
                ? baseLanguage?.globals?.common?.show_less_button_label ??
                  'Less'
                : baseLanguage?.globals?.common?.show_more_button_label ??
                  'More'
            }
            endIcon={showMoreHardSkills ? <ArrowDropUp /> : <ArrowDropDown />}
            onClick={() => setShowMoreHardSkills(!showMoreHardSkills)}
            data-cy="hard-skills-show-more-less-button"
          />
        </Stack>
        <Button
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          onClick={() => handleSkillsSubmit(role)}
          disabled={
            selectedSoftSkills.length === 0 && selectedHardSkills.length === 0
          }
          loading={isLoadingProfiles}
          data-cy="register-skills-continue-button"
        />
      </Stack>
    </ProgressContainer>
  );
}
