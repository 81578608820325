import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  AvatarBox,
  Button,
  DropdownItemProps,
  FormMultiSelectInput,
  FormTextArea,
  Loading,
  MultiSelectAreaInputValue,
  TextareaInputValue,
} from '@guider-global/ui';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import guideIcon from 'assets/users.svg';
import traineeIcon from 'assets/learn.svg';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressContainer } from 'containers';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ISkill } from '@guider-global/shared-types';
import { GroupsContext, GroupValues } from 'containers/Groups';
import { showAppAlert } from 'store/slices/appSlice';
import { useRelationships } from '@guider-global/front-end-hooks';

type GroupFormValues = {
  [key: string]: TextareaInputValue | MultiSelectAreaInputValue;
};

export function GroupDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const { groupValues, setGroupValues } = useContext(GroupsContext);

  const {
    programSlug = '',
    role,
    id,
  } = useParams<{
    programSlug: string;
    role: 'trainee' | 'guide';
    id: string | undefined;
  }>();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<GroupFormValues>({
    mode: 'onChange',
  });

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage, isLoadingBaseLanguage } = useBaseLanguage({
    localeCode,
  });

  // Program
  const { getProgram, isLoadingPrograms } = useOrganizationPrograms({
    organizationSlug,
  });
  const program = getProgram(programSlug);

  // Skills
  const skillsOptions = program?.registration?.skills?.options ?? [];

  // Relationships

  const {
    relationships,
    reqRelationships,
    isLoadingRelationships,
    isMutatingRelationships,
  } = useRelationships({});

  const relationship = relationships?.find(
    (relationship) => relationship.id === id,
  );

  useEffect(() => {
    if (isLoadingRelationships || loading) return;

    let title, description, skillSlugs;

    if (relationship?.group) {
      const currentSkills =
        (relationship?.skills as ISkill[])?.map((skill) => skill?.fieldSlug) ??
        [];

      title = relationship?.group?.title;
      description = relationship?.group?.description;
      skillSlugs =
        skillsOptions
          ?.filter(
            (skill) =>
              skill?.id?.current && currentSkills?.includes(skill?.id?.current),
          )
          .map((skill) => skill?.id?.current) ?? [];
    }

    if (groupValues) {
      title = groupValues?.title;
      description = groupValues?.description;
      skillSlugs = groupValues?.skillSlugs;
    }

    reset({
      title: {
        fieldType: 'free-text',
        value: title ?? '',
      },
      description: {
        fieldType: 'free-text',
        value: description ?? '',
      },
      skillSlugs: {
        fieldType: 'multi-select',
        value: skillSlugs ?? [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationship]);

  // Program text

  const groupRegistrationText =
    program?.program_details?.program_type?.program_type_text?.variations?.group
      ?.registration;
  const groupManageText =
    program?.program_details?.program_type?.program_type_text?.variations?.group
      ?.manage_group_profile;
  const groupTitle = program?.registration?.registration_group?.group_title;
  const groupDescription =
    program?.registration?.registration_group?.group_description;

  const minSkills =
    program?.registration?.skills?.multi_select_config?.minimum_selections;
  const maxSkills =
    program?.registration?.skills?.multi_select_config?.maximum_selections;

  const onSubmit: SubmitHandler<GroupFormValues> = async (
    data: GroupFormValues,
  ) => {
    setLoading(true);
    const { title, description, skillSlugs } = data;

    if (id && relationship) {
      const { status, message } = await reqRelationships({
        method: 'PATCH',
        url: `/relationships/${id}`,
        data: {
          skills: skillSlugs.value as string[],
          group: {
            title: title.value as string,
            description: description.value as string,
          },
        },
      });
      if (status === 'success') {
        navigate('/dashboard');
      } else {
        dispatch(
          showAppAlert({
            severity: 'error',
            message,
            timeout: 3000,
          }),
        );
      }
    } else {
      const groupData: GroupValues = {
        title: title.value as string,
        description: description.value as string,
        skillSlugs: skillSlugs.value as string[],
      };
      setGroupValues(groupData);
      navigate(`../preview-group`);
    }
    setLoading(false);
  };

  if (!role) return <></>;

  if (
    (isLoadingBaseLanguage || isLoadingRelationships || isLoadingPrograms) &&
    !loading
  ) {
    return (
      <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={66}>
        <Loading color="secondary" />
      </ProgressContainer>
    );
  }

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)} percentage={66}>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
      >
        <AvatarBox
          orientation="vertical"
          textSpacing={1.5}
          size="medium"
          heading={{
            text:
              relationship && id
                ? groupManageText?.title
                : groupRegistrationText?.registration_guide
                    ?.registration_guide_title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          avatarSrc={role === 'guide' ? guideIcon : traineeIcon}
          avatarSx={{
            p: 1.25,
            border: 'none',
            backgroundColor: '#F5F5F5',
          }}
        />

        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          direction={'column'}
          gap={1}
        >
          <FormTextArea
            control={control}
            rows={1}
            label={groupTitle?.question_title + '*'}
            description={groupTitle?.question_description}
            labelSize="xs"
            placeholder={groupTitle?.input_label}
            name={'title'}
            errorMessage={groupTitle?.required_validation_error_text}
            tooltipText={groupTitle?.input_assistive_text}
          />
          <FormTextArea
            control={control}
            rows={5}
            label={groupDescription?.question_title + '*'}
            description={groupDescription?.question_description}
            labelSize="xs"
            placeholder={groupDescription?.input_label}
            name={'description'}
            errorMessage={groupDescription?.required_validation_error_text}
            tooltipText={groupDescription?.input_assistive_text}
          />
          <FormMultiSelectInput
            required
            control={control}
            min={minSkills}
            max={maxSkills}
            minErrorMessage={
              program?.registration?.skills?.multi_select_config?.text
                ?.minimum_selections_not_met_validation_error
            }
            maxErrorMessage={
              program?.registration?.skills?.multi_select_config?.text
                ?.maximum_selections_exceeded_validation_error
            }
            labelSize="xs"
            name={'skillSlugs'}
            options={skillsOptions?.map((skill) => {
              return {
                label: skill?.label,
                key: skill?.id?.current,
                value: skill?.id?.current,
              } as DropdownItemProps;
            })}
            label={program?.registration?.skills?.[role]?.question_title}
            description={
              program?.registration?.skills?.[role]?.question_description
            }
            placeholder={program?.registration?.skills?.text?.input_label}
            tooltipText={
              program?.registration?.skills?.multi_select_config
                ?.select_skills_number_description
            }
          />

          <Button
            variant="contained"
            label={baseLanguage?.globals?.common?.continue_button_label}
            color="info"
            disabled={!isValid}
            data-cy="register-skills-continue-button"
            type="submit"
            loading={loading || isMutatingRelationships}
          />
        </Stack>
      </Stack>
    </ProgressContainer>
  );
}
