import { Button } from '@guider-global/ui';
import { ProgressContainer } from 'containers';
import { useNavigate, useParams } from 'react-router-dom';
import NoMatches from 'assets/no_relationships_avatar.svg';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getSubDomain,
  getVisibleProfileFields,
} from '@guider-global/front-end-utils';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import {
  EProgramVariation,
  ICustomField,
  IMatchV2,
  IRelationship,
  ProfileSkill,
} from '@guider-global/shared-types';
import { OrganizationProfileView } from '@guider-global/ui';
import {
  CircularAvatarPair,
  CustomModal,
  Loading,
  TextStack,
} from '@guider-global/ui/lib/components';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { MDIcons, SanityIcon } from 'components';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';
import { useMatchesV2 } from 'hooks/useMatchesV2';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import { NoMatchesView } from 'views';
import ConfettiExplosion from 'react-confetti-explosion';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';

export function MatchesPage() {
  // State
  const [matchingLoading, setMatchingLoading] = useState<boolean>(false);
  const [selectedProfileId, setSelectedProfileId] = useState<
    string | undefined
  >(undefined);

  const [showMatchedModal, setShowMatchedModal] = useState<boolean>(false);
  const [selectedMatch, setSelectedMatch] = useState<{
    match: IMatchV2 | undefined;
    relationshipId: string | undefined;
  }>({ match: undefined, relationshipId: undefined });

  // Hooks

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { getProfileImage } = useProfileImage();
  const { role } = useParams<{ role: 'trainee' | 'guide' }>();

  // Organization
  const { organization } = useOrganization({
    organizationSlug,
  });
  const { localeCode } = useLocalization(organizationSlug);
  // Base Language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Strings and assets

  const skipLabel = baseLanguage?.globals?.common?.skip_button_label ?? 'Skip';

  const profileCard = baseLanguage?.profile_card;

  const matchesToDisplay =
    organization?.open_matching.config.number_of_profiles_to_display;

  const programType = organization?.open_matching.program_type;

  // Matching

  const type = role === 'guide' ? 'guideProfile' : 'traineeProfile';

  const { matchesV2: matches, isLoadingMatchesV2 } = useMatchesV2({
    query: `?resultType=${type}&organizationSlug=${organizationSlug}&page=1&pageLimit=${
      matchesToDisplay ?? 3
    }`,
  });

  // Relationships
  const { reqRelationships } = useRelationships({});

  // Profiles
  const { profile, reqProfiles, isLoadingProfiles } = useProfiles({
    params: { integrationsEnabled: true },
  });

  // Effects

  function goToDashboard() {
    if (!profile) return;
    reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile.id}`,
      data: {
        isOnboarded: true,
      },
    }).then(() => {
      navigate('/dashboard');
    });
  }

  // Handle match

  const handleMatching = async (id: string, match: IMatchV2) => {
    setMatchingLoading(true);
    setSelectedProfileId(id);

    if (!profile) return;

    await reqRelationships(
      {
        method: 'PUT',
        url: '/relationships',

        data: {
          guideProfiles: [id],
          traineeProfiles: [profile.id],
          organizationSlug,
          programTypeSlug: programType?.program_type_slug,
          programVariationTypeSlug: EProgramVariation.Individual,
          profileId: profile.id,
        },
      },
      {
        onSuccess: (result) => {
          const relationshipId = (result?.data as IRelationship[])[0]?.id;
          reqProfiles(
            {
              method: 'PATCH',
              url: `/profiles/${profile.id}`,
              data: {
                isOnboarded: true,
              },
            },
            {
              onSuccess: () => {
                setSelectedMatch({
                  match,
                  relationshipId,
                });
                setShowMatchedModal(true);
                setMatchingLoading(false);
              },
              onError: () => {
                setMatchingLoading(false);
              },
            },
          );
        },
        onError: () => {
          setMatchingLoading(false);
          dispatch(
            showAppAlert({
              severity: 'error',
              message: 'Could not create relationship',
              timeout: 5000,
            }),
          );
        },
      },
    );
  };

  if (!baseLanguage)
    return (
      <ProgressContainer>
        <></>
      </ProgressContainer>
    );

  if (!matches || isLoadingMatchesV2)
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button variant="text" onClick={goToDashboard} label={skipLabel} />
        }
      >
        <Loading color="secondary" />
      </ProgressContainer>
    );

  if (matches?.length === 0 && !isLoadingMatchesV2) {
    const title = interpolate(
      baseLanguage?.registration?.open_matching?.matches?.empty_state_title,
      {
        rolePluralized:
          organization?.open_matching.program_type.program_type_text.common
            ?.guide?.pluralized ?? 'Mentors',
        roleSingular:
          organization?.open_matching.program_type.program_type_text.common
            ?.guide?.singular ?? 'Mentor',
      },
    );
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <Button variant="text" onClick={goToDashboard} label={skipLabel} />
        }
      >
        <NoMatchesView
          imageSrc={NoMatches}
          title={title}
          description={
            baseLanguage?.registration?.open_matching?.matches
              ?.empty_state_description
          }
          buttons={[
            {
              key: 'matches-btn-1',
              variant: 'outlined',
              label: baseLanguage?.globals?.common?.go_back_button_label,
              color: 'info',
              onClick: () => navigate(-1),
            },
            {
              key: 'matches-btn-2',
              variant: 'contained',
              label: baseLanguage?.globals?.common?.continue_button_label,
              color: 'info',
              onClick: goToDashboard,
              loading: isLoadingProfiles,
            },
          ]}
        />
      </ProgressContainer>
    );
  }

  const matchImage =
    getProfileImage({
      profilePicture: selectedMatch?.match?.profile?.picture,
      userPicture: selectedMatch?.match?.user?.picture,
    }) ?? '';

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: profile?.picture,
    }) ?? '';

  return (
    <ProgressContainer
      onBackButtonClick={() => navigate(-1)}
      endAdornment={
        <Button variant="text" onClick={goToDashboard} label={skipLabel} />
      }
    >
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '832px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <TextStack
          spacing={1.5}
          size="medium"
          heading={{
            text: interpolate(
              baseLanguage?.registration?.open_matching?.matches?.title,
              {
                matchesToDisplay: matchesToDisplay?.toString() ?? '3',
                roleSingular:
                  programType?.program_type_text?.common?.guide?.singular ??
                  'Mentor',
              },
            ),
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: baseLanguage?.registration?.open_matching?.matches
                ?.description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
        />

        {matches?.map((match, index) => {
          const profileFieldsData =
            getVisibleProfileFields(
              organization?.profile_fields,
              match?.profile?.organizationFields as ICustomField[],
            ) ?? [];

          const profileFields = profileFieldsData?.map((field) => {
            return {
              icon: <SanityIcon name={field?.icon?.name as MDIcons} />,
              label: field?.label?.toString() ?? '',
            };
          });

          const image = getProfileImage({
            profilePicture: match?.profile?.picture,
            userPicture: match.user.picture,
          });

          const buttonLabel = interpolate(
            programType?.program_type_text?.variations?.individual?.registration
              ?.registration_trainee?.registration_trainee_choose_a_guide
              ?.registration_trainee_choose_a_guide_choose_guide_button_label ??
              '',
            { name: match?.profile?.firstName ?? '' },
          );

          const bottomText =
            organization?.open_matching.config.guide_relationship_limit &&
            match.relationshipSpotsLeft &&
            interpolate(profileCard?.spots_left_label, {
              guideSingular:
                organization?.open_matching.program_type.program_type_text
                  .common?.guide?.singular ?? 'Mentor',
              spotNumber: match.relationshipSpotsLeft?.toString(),
              spot:
                match.relationshipSpotsLeft === 1
                  ? profileCard?.spot_singular
                  : profileCard?.spots_plural,
            });

          const matchText =
            match.percentage &&
            interpolate(baseLanguage?.profile_card?.match_percentage, {
              matchPercentage: match.percentage.toFixed(0),
            });

          const skills = match.skills.map((skill, index) => {
            const allSkills = [
              ...(organization?.open_matching.skills.soft_skills ?? []),
              ...(organization?.open_matching.skills.hard_skills ?? []),
            ] as ProfileSkill[];

            const label =
              allSkills.find((s) => s.slug === skill.fieldSlug)?.label ??
              undefined;

            return {
              key: `${'label'}-${index}`,
              isMatched: skill.matched ?? false,
              value: label ?? '',
            };
          });

          const goals = match.goalCategories.map((goalCategory, index) => {
            const label =
              organization?.goal_categories?.categories?.find(
                (g) => g.goal_category_slug.current === goalCategory.fieldSlug,
              )?.goal_category_name ?? undefined;

            return {
              key: `${'label'}-${index}`,
              isMatched: goalCategory?.matched ?? false,
              value: label ?? '',
            };
          });

          const matchSkills =
            match.skills.length !== 0
              ? {
                  title: profileCard?.matched_on_skills_label,
                  skills,
                }
              : undefined;

          const matchGoals =
            match.goalCategories.length !== 0
              ? {
                  title: profileCard?.matched_on_goals_label,
                  goals,
                }
              : undefined;

          return (
            <OrganizationProfileView
              key={match?.profile?.id}
              customFields={
                match?.profile?.bio
                  ? [
                      {
                        label: profileCard?.bio_title,
                        type: 'free-text',
                        value: match?.profile?.bio,
                      },
                    ]
                  : undefined
              }
              profileFields={profileFields}
              guideReviews={{
                reviewsPlural: profileCard?.reviews_plural,
                reviewsSingular: profileCard?.reviews_singular,
                buttonLabel: profileCard?.reviews_button_label,
                noReviewsText: profileCard?.no_reviews_yet_label,
                reviews: match.reviews,
              }}
              displayName={match?.profile?.displayName ?? ''}
              score={parseFloat(match.percentage.toFixed(2))}
              matchText={matchText}
              isBestMatch={index === 0}
              bestMatchLabel={
                baseLanguage?.programs?.program_registration?.best_match_label
              }
              subtitles={[
                match?.profile?.jobTitle && {
                  text: match?.profile?.jobTitle,
                  variant: 'subtitle1',
                },
                match?.profile?.townOrCity && {
                  text: `${match?.profile?.townOrCity}`,
                  variant: 'caption',
                  color: 'text.secondary',
                },
              ]}
              avatarSrc={image}
              skills={matchSkills}
              goals={matchGoals}
              linkedInUrl={match?.profile?.linkedInUrl}
              viewLinkedInLabel={
                baseLanguage?.programs?.program_registration
                  ?.linkedin_profile_button_label
              }
              buttons={[
                {
                  label: buttonLabel,
                  key: '1',
                  variant: 'contained',
                  color: 'info',
                  sx: { px: 3 },
                  onClick: () =>
                    handleMatching(match?.profile?.id ?? '', match),
                  loading:
                    matchingLoading && selectedProfileId === match?.profile?.id,
                  disabled:
                    matchingLoading && selectedProfileId !== match?.profile?.id,
                },
              ]}
              bottomText={bottomText}
            />
          );
        })}
      </Stack>
      <CustomModal isOpen={showMatchedModal}>
        <Stack direction={'row'} gap={0} spacing={0}>
          <ConfettiExplosion force={0.8} duration={5000} />
          <Stack
            direction={'column'}
            gap={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            px={5}
            py={4}
            width={'380px'}
          >
            <TextStack
              align="center"
              size="large"
              heading={
                baseLanguage?.registration?.open_matching?.matches
                  ?.its_a_match_title
              }
              subtitles={[
                {
                  text: interpolate(
                    baseLanguage?.registration?.open_matching?.matches
                      ?.its_a_match_description,
                    {
                      displayName:
                        selectedMatch?.match?.profile?.displayName ?? '',
                    },
                    '',
                  ),
                  variant: 'subtitle1',
                  color: 'text.secondary',
                  textAlign: 'center',
                },
              ]}
            />
            <CircularAvatarPair
              mainAvatar={matchImage}
              secondaryAvatar={userImage}
            />
            <Button
              fullWidth
              label={baseLanguage?.globals?.relationships?.view_relationship}
              variant={'contained'}
              color="info"
              onClick={() =>
                navigate(`/relationships/${selectedMatch?.relationshipId}`)
              }
            />
          </Stack>
          <ConfettiExplosion force={0.8} duration={5000} />
        </Stack>
      </CustomModal>
    </ProgressContainer>
  );
}
